<template>
  <div class="container">
    <h4 class="text-center my-4">Подача заявки на разницу</h4>

    <PrimePreloader v-if="loading"/>

    <div v-else class="my-3">

      <div class="row my-4">
        <p class="text-danger">Перед написанием заявления проверьте номер телефона на странице
          <router-link to="/profile" target="_blank">Мой профиль</router-link>
        </p>
      </div>

      <div class="my-3">
        <Button icon="pi pi-plus" label="Добавить заявление" class="p-button-rounded"
                @click="openAddApplication"/>
      </div>

      <div v-if="differenceApplications.length">
        <DataTable :value="differenceApplications" :paginator="true" :rows="10"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   currentPageReportTemplate="Показано {last} из {totalRecords}"
                   stripedRows responsiveLayout="scroll">

          <Column header="Файл">
            <template #body="{data}">
              <div v-if="!data.file">Квитанции нет</div>
              <div v-else>
                <a :href="`https://back.uib.kz/uploads/${data.file}`" target="_blank">Квитанция</a>
              </div>
            </template>
          </Column>
          <Column header="Дата">
            <template #body="{data}">
              {{ convertTimestampToDate(data.created_at) }}
            </template>
          </Column>
          <Column header="Дисциплины">
            <template #body="{data}">

              <ul class="mb-1">
                <li v-for="(disciplines, disciplineIndex) in data.disciplines" :key="disciplineIndex">
                  {{disciplines.name}} - Количество кредитов: {{ disciplines.credit}}
                </li>
              </ul>

            </template>
          </Column>
          <Column header="Статус">
            <template #body="{data}">
              <div v-if="+data.status===0">Отправлено</div>
              <div v-else-if="+data.status===1">Потверждено</div>
              <div v-else-if="+data.status===2">Отказано</div>
            </template>
          </Column>

        </DataTable>
      </div>

      <Dialog v-model:visible="displayAddApplication" :style="{width: '100%',maxWidth: '1000px'}" :closable="false">
        <template #header>
          <h5>Добавить заявление</h5>
        </template>

        <div>
          <div class="my-3">
            <p class="text-center">Заявление</p>
            <!--            <p>-->
            <!--              Прошу Вас разрешить освоение следующих дисциплин в летнем семестре 2022 - 2023 учебного года по причине:-->
            <!--            </p>-->
          </div>


          <DataTable :value="differenceDisciplines" showGridlines stripedRows responsiveLayout="scroll">
            <Column field="education_discipline_name" header="Название дисциплины"></Column>
            <Column field="credit" header="Кредит"></Column>
            <Column header="Сумма к оплате">
              <template #body="{data}">
                {{ Math.round(data.price_sum) }}
              </template>
            </Column>
            <Column header="Выбор">
              <template #body="{data}">
                <input class="form-check-input" type="checkbox" :value="data" v-model="checkedDisciplines">
              </template>
            </Column>
          </DataTable>

          <div class="mt-5" v-if="checkedDisciplines.length">

            <div>
              Общая сумма: <b>{{totalPriceSum}}</b>
            </div>

            <div class="row mt-4">
              <label class="col-md-3 col-form-label">Прикрепите чек</label>
              <div class="col-md-9">
                <input type="file" accept="image/png, image/jpg, image/jpeg" class="form-control"
                       @change="handleFileUpload($event)">
              </div>
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeAddApplication"/>
          <Button v-if="checkedDisciplines.length&&checkFile" label="Добавить" icon="pi pi-check"
                  @click="saveApplication"/>
        </template>
      </Dialog>

    </div>

  </div>
</template>

<script>
  import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
  import {mapActions, mapState} from "vuex"

  export default {
    name: "DifferenceStudentApplication",
    data() {
      return {
        loading: true,
        differenceDisciplines: [],
        differenceApplications: [],

        displayAddApplication: false,
        checkedDisciplines: [],
        checkFile: null,
      }
    },
    computed: {
      ...mapState('academicDebt', ['academicDebt_form']),
      totalPriceSum() {
        return this.checkedDisciplines.reduce((sum, i) => Math.round(+i.price_sum) + sum, 0)
      },
    },

    methods: {
      ...mapActions('differenceStudent', [
        'GET_DIFFERENCE_STUDENT_DISCIPLINES',
        'GET_DIFFERENCE_STUDENT_APPLICATION',
        'POST_DIFFERENCE_STUDENT'
      ]),
      convertTimestampToDate,
      openAddApplication() {
        this.checkFile = null
        this.checkedDisciplines = []

        this.displayAddApplication = true
      },
      closeAddApplication() {
        this.displayAddApplication = false
      },
      handleFileUpload(e) {
        this.checkFile = e.target.files[0]
      },
      async saveApplication() {
        this.displayAddApplication = false

        const disciplines = this.checkedDisciplines.map(i => ({difference_ratings_id: i.difference_ratings_id}))

        let form = new FormData();
        form.append("file_url", this.checkFile)
        form.append("disciplines", JSON.stringify(disciplines))

        const res = await this.POST_DIFFERENCE_STUDENT(form)
        if (res) {
          await this.getDifferenceDisciplines()
          await this.getDifferenceApplications()
          this.$message({text: 'Заявка успешно подана'})
        }
      },
      async getDifferenceDisciplines() {
        this.differenceDisciplines = await this.GET_DIFFERENCE_STUDENT_DISCIPLINES()
      },
      async getDifferenceApplications() {
        this.differenceApplications = await this.GET_DIFFERENCE_STUDENT_APPLICATION()
      },
    },

    async mounted() {
      await this.getDifferenceDisciplines()
      await this.getDifferenceApplications()

      this.loading = false
    }
  }
</script>

<style scoped>

</style>